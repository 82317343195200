<script>
export default {
  name:"AIFistFooter",
  data: () => ({
    icons: [
      {
        text: "mdi-facebook",
        link: "https://web.facebook.com/profile.php?id=61550062203303&mibextid=ZbWKwL&_rdc=1&_rdr",
      },
      // {
      //   text: "mdi-twitter",
      //   link: "https://twitter.com/NizamaniIshaque",
      // },
      {
        text: "mdi-linkedin",
        link: "https://www.linkedin.com/company/aifist/?viewAsMember=true",
      },
      {
        text: "mdi-github",
        link: "https://github.com/AIFist",
      },
    ],
  }),
};
</script>

<template>
    <v-footer dark padless>
      <v-card flat tile class="secondary white--text text-center">
        <v-card-text class="d-flex flex-wrap justify-center ">
          <v-btn
            v-for="(icon, i) in icons"
            :key="i"
            class="mx-4 white--text "
            :href="icon.link"
            target="_blank"
            icon
          >
            <v-icon size="24px" class="hover-btn">{{ icon.text }}</v-icon>
          </v-btn>
        </v-card-text>
  
        <v-card-text class="white--text pt-0 white-text">
            Copyright ©2024 All rights reserved | Developed by AIFist
        </v-card-text>
  
        <v-divider></v-divider>
  
        <v-card-text class="white--text white-text">
          {{ new Date().getFullYear() }} — <strong>AIFist</strong>
        </v-card-text>
      </v-card>
    </v-footer>
  </template>
  
  <style scoped>
  .v-footer {
    background-color: #323F47;
  }
  .v-card {
    width: 100%;
    background-color: #323F47;
  }
  .white-text {
    color: white;
  }
  .hover-btn:hover {
    transition: 0.6s;
    transform: scale(1.8);
  }
  
  </style>
  
 