<template>
  <section id="home">
    <v-parallax dark src="@/assets/img/bghero2.jpg" class="bg-hero" >
      <div class="white-text-div">
        <v-row align="center">
          <v-col cols="12" md="6" xl="8">
            <h1 class="font-weight-bold display-title">AIFist</h1>
            <h1 class="font-weight-light white-text">
              Beyond Boundaries <br />
              Safe AGI for a Better Tomorrow. <br />
              Safe Future with AI.
            </h1>

            <v-btn
              :class="{ 'display-btn': !smallScreen, 'display-btn-small': smallScreen }"
              outlined
              large
              dark
              @click="goToSection('#contact')"
            >
              Get Started
              <v-icon class="ml-2">mdi-arrow-down</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </v-parallax>
  </section>
</template>

<style scoped>
#home {
  height: auto;
}

.bg-hero {
  height:750px;
}
.display-title {
    font-size: 4rem;
  }
  .white-text {
    font-size: 3rem;
  }
.white-text-div {
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
  width: 95%;
  color: white;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-size: 1.8rem;
  font-weight: bolder;
  margin-top: 13rem;
  margin-left: 5.5rem;
  text-align: left;
}

/* Responsive Styling */
@media only screen and (max-width: 600px) {
  .white-text-div {
    width: 80%;
    margin-left: 2rem;
    margin-top: 12rem;
    text-align: left;
    margin-left: 4rem;
  }
  .bg-hero {
  height: 500px;
}

  .display-title {
    font-size: 2.5rem;
  }

  .white-text {
    font-size: 1.5rem;
  }

  /* Specific styling for small screens */
  .display-btn-small {
    font-size: 1rem;
    background: transparent;
    color: #FFFFFF;
    outline: 1px solid #FFFFFF;
    margin-top: 1rem;
  }
}

/* Specific styling for big screens */
.display-btn {
  margin-top: 20px;
  font-size: 1.5rem;
  background: transparent;
  color: #FFFFFF;
  outline: 1px solid #FFFFFF;
}
</style>


<script>
export default {
  name: "AIfistHomepage",
  data() {
    return {
      smallScreen: false,
    };
  },
  mounted() {
    // Check screen size on mount
    this.checkScreenSize();

    // Listen for window resize events
    window.addEventListener('resize', this.checkScreenSize);
  },
  beforeUnmount() { // Use beforeUnmount instead of beforeDestroy
    // Remove the resize event listener when the component is destroyed
    window.removeEventListener('resize', this.checkScreenSize);
  },
  methods: {
    checkScreenSize() {
      // Update smallScreen value based on window width
      this.smallScreen = window.innerWidth <= 600;
    },
    goToSection(sectionId) {
      const element = document.querySelector(sectionId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    },
  },
};
</script>