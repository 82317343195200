<script>
import emailjs from 'emailjs-com';
// import VueRecaptcha from 'vue-recaptcha-v3';

export default {
  name:"AIfistContactSection",
  // components: {
  //   VueRecaptcha
  // },
  data: () => ({
    icons: ["fa-facebook", "fa-twitter", "fa-linkedin", "fa-instagram"],
    valid: true,
    name: "",
    // isRecaptchaLoaded: false,
    formRef: null,
    // isVerified: false,
    isLoading: false,
    visualViewport: false,
    nameRules: [
      (v) => !!v || "The name field is required",
      (v) => (v && v.length >= 6) || "The name must be longer than 6 characters",
    ],
    email: "",
    emailRules: [
      (v) => !!v || "The email field is required",
      (v) => /.+@.+\..+/.test(v) || "Email must be valid",
    ],
    textArea: "",
    textAreaRules: [
      (v) => !!v || "The text field is required",
      (v) => (v && v.length >= 20) || "Minimum of 20 characters",
    ],
    lazy: false,
    snackbar: {
      enabled: false,
      text: '',
      color: ''
    }
  }),

  mounted() {
  //   if (this.$recaptcha) {
  //   this.$recaptcha.execute();
  //   console.log('reCAPTCHA is loaded');
  // } else {
  //   // Handle the case where reCAPTCHA isn't ready yet
  //   console.log('reCAPTCHA not yet loaded');
  // }
},
methods: {
  // handleVerify(response) {
  //     console.log('reCAPTCHA verified:', response);
  //     this.isVerified = true; // Update verified state
  //   },
    submit() {
      this.isLoading = true;

      const vForm = this.$refs.form;
      if (vForm.validate()) {
      //   if (!this.isVerified) {
      //   console.error('reCAPTCHA verification required');
      //   this.isLoading = false;
      //   return;
      // }
        const formData = {
          name: this.name,
          email: this.email,
          message: this.textArea,
          // message: this.$refs.message.value
          // ... add other form fields similarly
        };
        // const formData = new FormData(vForm.$el);
        // console.log(vForm.$el);
        console.log(formData);
        setTimeout(() => {
        emailjs
          .send('service_w7bq6us', 'template_e97wzfu',formData, 'D76rMXZy4kvmVyqfh',
          )
          .then(
            () => {
              console.log('SUCCESS!');
              this.name = '';
              this.email = '';
              this.textArea = '';
              vForm.resetValidation();
              // handle success, show snackbar or other feedback
              this.snackbar = { enabled: true, text: 'Form submitted successfully!', color: 'success' };
            },
            (error) => {
              console.log('FAILED...', error.text);
              // handle failure, show snackbar or other feedback
              this.snackbar = { enabled: true, text: 'Form submission failed!', color: 'error' };
            }
          )
          .finally(() => {
            this.isLoading = false; // Reset loading state after submission
          });
        }, 2000);
      } else {
        console.error('Form element not found');
        this.isLoading = false;
        // handle error, show snackbar or other feedback
        this.snackbar = { enabled: true, text: 'Form element not found!', color: 'error' };
      }
    },
  },
};
</script>

<template>
  <section class="pb-8" id="contact">
    <v-container fluid class="main-cont contain-css">
      <v-row align="center" justify="center">
        <v-col cols="10">
          <v-row justify="center">
            <v-col cols="12" sm="5">
              <h1 class="display-1">Contact-Us</h1>
              <h3 class="cont-title">Get in Touch</h3>
              <h3 class="font-weight-light mt-3">
                We'd love to hear from you! Whether you have a question about our services, need assistance, or just want to say hello, we're here for you. Feel free to reach out using the contact information below
              </h3>
              <!-- <h3 class="font-weight-light mt-3">
                Lorem ipsum dolor sit amet consectetur adipisicing.
              </h3> -->
              <h3 class="font-weight-light mt-3">
                Phone: <a class="phone-color">+923464897524</a>
              </h3>
              <span class="font-weight-light">
                Email: <a class="email-color" href="mailto:support@aifist.com">support@aifist.com</a>
              </span>
            </v-col>
            <v-col cols="12" sm="7">
              <v-form ref="form"  @submit.prevent="submit" v-model="valid" :lazy-validation="lazy" id="contact-form">
                <v-text-field
                class="name-ind"
                  v-model="name"
                  :rules="nameRules"
                  label="Name"
                  required
                />
  
                <v-text-field
                class="email-ind"
                  v-model="email"
                  :rules="emailRules"
                  label="Email"
                  required
                />
  
                <v-textarea
                class="message-ind"
                  v-model="textArea"
                  :rules="textAreaRules"
                  label="Message"
                  required
                />
                
                <!-- <vue-recaptcha
                 ref="recaptcha"
                  @verify="handleVerify"
                   :sitekey="'6Lft-4cpAAAAAMFYl6dOtldt6YjwTXxaOeaTxoJf'">
                  </vue-recaptcha> -->
                  
                <v-btn
                  :disabled="!valid || isLoading"
                  color="#323F47"
                  :dark="valid"
                  rounded
                  block
                  class="mt-3"
                  @click="submit"
                  >
                  <span v-if="!isLoading">Submit</span>
                  <v-progress-circular
                    v-else
                    indeterminate
                    color="white"
                    size="20"
                  ></v-progress-circular>
                </v-btn>
              
              </v-form>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar
      v-model="snackbar.enabled"
      timeout="3000"
      right
      top
      :color="snackbar.color"
      >
      {{ snackbar.text }}
  
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="snackbar.enabled = false"
          >
          Fechar
        </v-btn>
      </template>
    </v-snackbar>
  </section>
</template>
  
<style scoped>
  #contact {
    background-color: #f4f7f5;
  }
  .display-1 {
    font-size: 2.1rem;
    font-weight: bold;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  }

  .cont-title {
    margin-top: 5px;
    font-size: 1.4rem;
  }

  .name-ind {
    background-color: #f4f7f5;
    transition: border-bottom-color 0.9s ease;
  }
  .email-ind {
    background-color: #f4f7f5;
  }

  .message-ind {
    background-color: #f4f7f5;
  }
  .contain-css{
    padding-top: 60px;
    padding-bottom: 60px;
  }
  
  .email-color
  {
    color: #44535b;
    font-weight: bold;
  }


  .email-color:hover {
    /* Add your hover styles here */
    color: #7cc0e0; /* Change to the color you want on hover */
    text-decoration: underline; /* Optional: Add underline on hover */
    font-size: 1.05em;
    transition: 0.6s;
  }
  .phone-color{
    color: #44535b;
    font-weight: bold;
  }
  .phone-color:hover {
    /* Add your hover styles here */
    color: #7cc0e0; /* Change to the color you want on hover */
    text-decoration: underline; /* Optional: Add underline on hover */
    font-size: 1.05em;
    transition: 0.6s;
  }
</style>