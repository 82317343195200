import { createApp } from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import { createRouter, createWebHistory } from 'vue-router';
// import VueRecaptcha from 'vue-recaptcha-v3';
loadFonts()
const router = createRouter({
  history: createWebHistory(),
  routes: [
    // Your Vue Router routes here
  ],
});
createApp(App)
  // .use(VueRecaptcha, { siteKey: '6Lft-4cpAAAAAMFYl6dOtldt6YjwTXxaOeaTxoJf' })
  .use(router)
  .use(vuetify)
  .mount('#app')
