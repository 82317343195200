<!-- NavBar.vue -->

<script>
  export default {
    name: 'AIfistNavBar',

      data: () => ({
        drawer: null,
        isXs: false,
        items: [
          ["mdi-home-outline", "Home", "#home"],
          ["mdi-cart-arrow-down", "products", "#products"],
          ["mdi-information-outline", "About", "#about"],
          ["mdi-account-group", "Team", "#team"],
          ["mdi-email-outline", "Contact", "#contact"],
        ],
      }),
      props: {
        color: String,
        flat: Boolean,
      },
      methods: {
        onResize() {
          this.isXs = window.innerWidth < 850;
        },
        goToSection(sectionId) {
        const element = document.querySelector(sectionId);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      },
    },
    

    watch: {
      isXs(value) {
        if (!value) {
          if (this.drawer) {
            this.drawer = false;
          }
        }
      },
    },
    mounted() {
      this.onResize();
      window.addEventListener("resize", this.onResize, { passive: true });
    },
  };
</script>

<template>
  <div>
    <v-navigation-drawer
      class="nd-head"
      v-model="drawer"
      app
      temporary
      dark
       
      >
      <v-list class="vl-head">
        <v-list-item>
          <v-list-item-content>
            <div class="logo-div-drawer" justify="center">
              <v-img
                class="rounded-image-drawer"               
                src="@/assets/aifistlogobgremove.png"
                alt="AIfist Logo"
              ></v-img>
            </div>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider />

      <v-list dense class="vlc-head">
        <v-list-item
          class="vlc-item"
          v-for="([icon, text, link], i) in items"
          :key="i"
          link
          @click="goToSection(link)"
          >
          <v-list-item-icon class="justify-center">
            <v-icon>{{ icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="subtitile-1">
              {{ text }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      app
      :color="color"
      :flat="flat"
      dark
      class="px-15"
      :class="{ expand: flat }"
      >
      <v-toolbar-title>
        <div class="logo-div  " justify="center">
          <v-img
            class="rounded-image"
            src="@/assets/aifistlogobgremove.png"
            alt="AIfist Logo"
          ></v-img>
        </div>
      </v-toolbar-title>
      <v-spacer />
      <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
        class="mr-4"
        v-if="isXs"
        :color="'white'"
        />
       <div v-else>
        <v-btn text @click="goToSection('#home')">
          <span class="mr-2">Home</span>
        </v-btn>
        <v-btn text @click="goToSection('#products')">
          <span class="mr-2">Products</span>
        </v-btn>
        <v-btn text @click="goToSection('#about')">
          <span class="mr-2">About</span>
        </v-btn>
        <v-btn text @click="goToSection('#team')">
          <span class="mr-2">Team</span>
        </v-btn>
        <v-btn rounded outlined text  class="contact-btn" @click="goToSection('#contact')">
          <span class="contact-style mr-2">Contact</span>
        </v-btn>
      </div>
    </v-app-bar>
  </div>
</template>

<style scoped>
  .v-toolbar {
    transition: 0.6s;
    position: relative;
  }
  .rounded-image {
  height: 70px;
  width: 70px;
  align-items: center;
  transform: scale(1.5);
}

  .expand {
    height: 80px !important;
    padding-top: 10px;
    position: relative;
  }

  .mr-2 {
    color: white;
    font-size: 1rem;
  }

  .mr-2:hover {
    transition: 0.5s;
    transform: scale(1.2);
  }

   .contact-btn {
    color: white;
    width: 120px;
    font-size: 1rem;
    margin-left: 10px;
    border-radius: 30px;
    border: 2px solid rgb(229, 229, 229, 0.2);
  }
  .contact-style {
    color: white;
    width: auto;
    font-size: 1rem;
    padding: 5px;
    padding-left: 10px;
    border-radius: 30px;
    
  }

  .nd-head {
    background-color: #323f47;
  }
  .vlc-head {
    align-items: center;
    position: relative;
  }
  .vlc-item:hover {
    transition: 0.2s;
    border: 3px solid rgba(214, 214, 214, 0.05);
  }
  .justify-center {
    color: white;
    font-size: 1.4rem;
    margin-left: 32px;
    position: absolute;
  }
  .subtitile-1 {
    color: white;
    font-weight: 600;
    font-size: 1.5rem;
    margin-left: 80px;
    margin-top: 8px;
    width: 50%;
  }

  .logo-div{
    background-color: #fffefe;
    width: 60px;
    height: 60px;
    border-radius: 20%;
    align-items: center;
    padding: 0;
    margin: 0;
   box-sizing: border-box;
  }
  
  @media only screen and (max-width: 600px) {
    /* Adjust the max-width based on your needs */
    .rounded-image {
      height: 40px; /* Adjust the height for smaller screens */
      width: 40px; /* Adjust the width for smaller screens */
      transform: scale(1.5); /* Reset the scale for smaller screens */
    }
    .logo-div{
    background-color: #fffefe;
    width: 40px;
    height: 40px;
    border-radius: 20%;
    align-items: center;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }
  .logo-div-drawer{
    background-color: #fffefe;
    width: 40px;
    height: 40px;
    border-radius: 20%;
    align-items: center;
    padding: 0;
    margin-left: 85px;
   box-sizing: border-box;
  }
  .rounded-image-drawer {
      height: 40px; /* Adjust the height for smaller screens */
      width: 40px; /* Adjust the width for smaller screens */
      transform: scale(1.5); /* Reset the scale for smaller screens */
    }
  }

</style>