<script>
export default {
name: "AIFistTeamSection",
data() {
  return {
    dialog: false,
    videoId: "i8IvvHJssWE",
    features: [
      {
        img: require("@/assets/img/salman.jpeg"),
        name: "Salman Nizamani",
        role: "CEO & Co-founder",
        title: "DevOps Engineer",
        github: "https://github.com/Salman-18",
        linkedin: "https://www.linkedin.com/in/salman-nizamani-7360a71b7/",
        twitter: "https://twitter.com/Salman29026134"
      },
      {
        img: require("@/assets/img/ishaque.jpeg"),
        name: "M.Ishaque Nizamani ",
        role: "CTO & Co-founder",
        title: "Backend & ML Engineer",
        github: "https://github.com/MuhammadNizamani",
        linkedin: "https://www.linkedin.com/in/muhammad-ishaque-nizamani-109a13194/",
        twitter: "https://twitter.com/NizamaniIshaque"
      },
      {
        img: require("@/assets/img/ahmed.jpeg"),
        name: "Ahmed Nizamani",
        role: "Co-founder",
        title: "Mobile Engineer",
        github: "https://github.com/Ahmedniz",
        linkedin:"https://www.linkedin.com/in/ahmed-nizamani-a69803135/",
        twitter: "https://twitter.com/ahmednizamani01"
      },
      {
        img: require("@/assets/img/anshal.jpg"),
        name: "Anshal Khaskheli",
        role: "Co-founder",
        title: "Web Engineer",
        github: "https://github.com/Anshalali",
        linkedin:"https://www.linkedin.com/in/anshal-ali-1b3350240/",
        twitter:"https://twitter.com/anshalali72"
      },
    ],
  };
},

};
</script>

<template>
  <section id="team">
      <v-container fluid id="features" class="mt-5">
        <h1 class="text-center title" >Team</h1>
        <h1 class="down-title">Meet our team</h1>
    <v-row align="center" justify="center" class="card-body-margin">
      <v-col cols="10">
        <v-row align="center" justify="space-around">
          <v-col
            cols="12"
            sm="3"
            class="text-center"
            v-for="(feature, i) in features"
            :key="i"
          >
            <v-hover >
              <template v-slot:default="{ isHovering, props}">
              <v-card
                class="card"
                v-bind="props"
                shaped
                :elevation="isHovering ? 20 : 4"
                :class="{ up: isHovering }"
              >
                <v-img
                  :src="feature.img"
                  max-width="200px"
                  class="d-block ml-auto mr-auto upMargin rounded-image"
                  :class="{ 'zoom-efect': isHovering }"
                ></v-img>
                <h2 class="abhaya-libre-bold text-color">{{ feature.name }}</h2>
                <h3 class="abhaya-libre-semibold subtitle-1">{{ feature.role }}</h3>
                <h4 class="abhaya-libre-semibold title-1">{{ feature.title }}</h4>
                
                <div class="social-icons">
                  
                  <a :href="feature.twitter" target="_blank" class="icon-space">
                    
                    <img class="twitter-icon"
                      :src="require('@/assets/img/twitter-icon.png')"
                       alt="Twitter"
                      
                      >
                    
                  </a>
              
                  <a :href="feature.github" target="_blank" class="icon-space">
                    <img class="github-icon" :src="require('@/assets/img/github-icon.png')" alt="GitHub">
                  </a>
                  <a :href="feature.linkedin" target="_blank" >
                    <img class="linkedin-icon" :src="require('@/assets/img/color-llinked-icon.png')" alt="LinkedIn">
                  </a>

  
                  </div>
              </v-card>
              </template>
            </v-hover>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>

  </section>

</template>



<style scoped>
#team {
  height: auto;

}
.rounded-image {
  margin-top: 35px;
  margin-bottom:  20px;
  border-radius: 50%;
}
.bg-color{
  background:transparent;
}

.text-color {
  color: #000000;
  font-size: 1.7rem;
  font-weight: 600;
}
.card {
  width: 100%;
  min-height: auto;
  padding: 10px;
  margin-right: 2rem;
  border-radius: 30px 5px 30px 5px;
  transition: 0.5s ease-out;
  background-color: #F4F7F5;
}

.card .v-image {
  margin-bottom: 15px;
  transition: 0.75s;
}

.card h1 {
  margin-bottom: 10px;
}

.zoom-efect {
  transform: scale(1.1);
  transition: 0.8s;
}

.up {
  transform: translateY(-20px);
  transition: 0.5s ease-out;
}
.abhaya-libre-regular {
  font-family: "Abhaya Libre", serif;
  font-weight: 400;
  font-style: normal;
}
.abhaya-libre-bold {
  font-family: "Abhaya Libre", serif;
  font-weight: 700;
  font-style: normal;
}
.abhaya-libre-semibold {
  font-family: "Abhaya Libre", serif;
  font-weight: 600;
  font-style: normal;
}
.title {
  margin-top: 0.3rem;
  font-size: 2.7rem;
  font-weight: bold;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  text-align: center;
}
.down-title {
  font-weight: 300;
  font-size: 1.7rem;
  color: #7b7b7b;
  text-align: center;
}
.card-body-margin {
  margin-top: 2.8rem;
}
.icon_size {
  font-size: 30px;
}
.twitter-icon {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}
.twitter-icon:hover {
    opacity: 0.5;
    transition: 0.4s;
    transform: scale(1.2);
    text-shadow: -1px -1px 3px #615e5e, 0px -0px 0 #fffefe, -0px 0px 0 #575757, 0px 0px 0 #575757
}
.github-icon {
  width: 35px;
  height: 35px;
  margin-right: 10px;
}
.github-icon:hover {
    opacity: 0.5;
    transition: 0.4s;
    transform: scale(1.2);
    text-shadow: -1px -1px 3px #615e5e, 0px -0px 0 #fffefe, -0px 0px 0 #575757, 0px 0px 0 #575757
}
.social-icons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
  
}
.linkedin-icon
{
   width: 37px;
  height: 37px;
  margin-right: 10px;
}
.linkedin-icon:hover {
    opacity: 0.5;
    transition: 0.4s;
    transform: scale(1.2);
    text-shadow: -1px -1px 3px #615e5e, 0px -0px 0 #fffefe, -0px 0px 0 #575757, 0px 0px 0 #575757
}
.icon-space
{
  margin-right: 5px;
}
.title-1 {
  color: #7b7b7b;
  font-size: 1.3rem;
}
</style>