<script>
import AIfistNavBar from '@/components/NavBar.vue';
import AIfistHomepage from '@/components/homepage.vue';
import AIfistProdcutSection from '@/components/productsection.vue';
import AIfistAboutSection from '@/components/aboutsection.vue';
import AIfistContactSection from '@/components/contactSection.vue';
import AIFistFooter from '@/components/Footer.vue';
import AIFistTeamSection from '@/components/teamsection.vue';
export default {
  components:{
    AIfistNavBar,
    AIfistHomepage,
    AIfistProdcutSection,
    AIfistAboutSection,
    AIfistContactSection,
    AIFistFooter,
    AIFistTeamSection,
  },
  data: () => ({
    fab: null,
    color: "",
    flat: null,
  }),

  created() {
    const top = window.scrollY || 0;
    if (top <= 60) {
      this.color = "transparent";
      this.flat = true;
    }
  },

  watch: {
    fab(value) {
      if (value) {
        this.color ="#323F47";
        
        
        this.flat = false;
      } else {
        this.color = "transparent";
        this.flat = true;
      }
    },
  },

  methods: {
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.scrollY || e.target.scrollTop || 0;
      this.fab = top > 60;
    },
    toTop() {
      const element = document.querySelector("#home");
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
 
    },
    },
 
};
</script>

<template>
  <v-app>
    <AIfistNavBar  :color="color" :flat="flat" />
      <v-main class="pt-0">
      
      <AIfistHomepage  />
      <AIfistProdcutSection/>
      <AIfistAboutSection />
      <AIFistTeamSection />
      <AIfistContactSection />
    </v-main>
    <VLayoutItem model-value position="bottom" class="text-end pointer-events-none" size="88">
      <div class="ma-4 pointer-events-initial">
        <VBtn  v-scroll="onScroll" icon="mdi-arrow-up" size="large" :color="color" elevation="8" @click="toTop" v-show="fab" />
      </div>
    </VLayoutItem>
 
    <AIFistFooter />
  
    <!-- </v-container> -->
  </v-app>
</template>

<style scoped>
  .v-main {
    background-image: url("~@/assets/img/bgMain.png");
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
  }
  .pointer-events-none {
    pointer-events: none;
  }
  
  .pointer-events-initial {
    pointer-events: initial;
  }
</style>