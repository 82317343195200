<script>
export default {
  name: "AIfistAboutSection",
};
</script>
<template>

    <section id="about">
      <v-container fluid> 
        <h1 class=" main-title text-center">About Us</h1>
                <h1 class="font-weight-light sub-title mb-3 text-center">
                  Safe Future with AI
                </h1>
        <v-row align="center" justify="center">
          <v-col cols="10">
            <v-row align="center" justify="center">
              <v-col cols="12" md="7">

               
                
              <v-row>
                <v-col cols="12" class="d-flex align-center">
                  <v-icon size="50px" class ="mr-4">mdi-head-lightbulb-outline</v-icon>
                  <div>
                    <h2 class="font-weight-bold mb-2">What We Do</h2>
                    <p class="text-justify">
                      Welcome to AIFist, a Pakistan-based AI company dedicated to leveraging
                      artificial intelligence for the betterment of society. Our mission is to
                      harness the power of AI responsibly and safely, integrating innovative
                      solutions into our products to drive positive impact.
                    </p>
                  </div>
                </v-col>
                <v-col cols="12" class="d-flex align-center">
                  <v-icon size="50px" class ="mr-4">mdi-account-group</v-icon>
                  <div>
                    <h2 class="font-weight-bold mb-2">Our Expertise</h2>
                    <p class="text-justify">
                      Currently, we are actively seeking new projects and collaborations. If you
                      have a groundbreaking idea or a unique product in need of AI integration,
                      we're here to turn your vision into reality. Our team is equipped to handle
                      diverse challenges, and we're open to exploring new avenues.
                    </p>
                  </div>
                </v-col>
                <v-col cols="12" class="d-flex align-center">
                  <v-icon size="50px" class ="mr-4 mr-3">mdi-sitemap</v-icon>
                  <div>
                    <h2 class="font-weight-bold mb-2">Ongoing Projects</h2>
                    <p class="text-justify pos">
                      Currently, we are deeply immersed in two exciting projects. The first
                      involves the development of an EdTech app using Language Model (LLM)
                      technology.
                      <br />
                      <br />
                      Our second project involves collaboration with Walmart for a seller-centric
                      extension. This endeavor showcases our commitment to partnering with industry
                      leaders and implementing AI solutions that enhance efficiency and drive success.
                    </p>
                  </div>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="5" class="d-none d-md-flex">
              <v-img
                src="@/assets/img/about-img.png"
                class="d-block ml-auto mr-auto"
                max-width="600px"
              ></v-img>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
     
    </v-container>
  </section>
</template>


  <style scoped>
  #about {
    background-color: #F4F7F5;
    
  }
  .main-title {
    margin-top: 2rem;
    font-size: 2.7rem;
    font-weight: bold;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    text-align: center;
  }
  .sub-title{
    font-size: 1.7rem;
    color: #7b7b7b;
  }
  .pos{
    padding-bottom: 60px;
  }
  .mr-4 {
    margin-top: 2rem;
  }
  .mr-3 {
    margin-bottom: 5rem;
  }
  </style>
