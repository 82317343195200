<template>
  <section id="products">
    <h1 class="title-text">Products</h1>
    <h1 class="font-weight-light sub-title mb-3 text-center title-down-text">Welcome to the products section</h1>
    <v-container fluid id="features" class="mt-2">
      <v-row align="center" justify="center">
        <v-col cols="10">
          <v-row align="center" justify="space-around">
            <v-col
            cols="12"
            sm="4"
            class="text-center"
            v-for="(feature, i) in features"
            :key="i"
            >
              <v-hover >
              <template v-slot:default="{ isHovering, props}">
                      <v-card
                      class="card "
                      v-bind="props"
                      shaped
                      :elevation="isHovering ? 20 : 4"
                      :class="{ up: isHovering }"
                      >
                          <v-img
                            :src="feature.img"
                            max-width="400px"
                            class="d-block ml-auto mr-auto content-image"
                            :class="{ 'zoom-efect': isHovering }"
                          ></v-img>
                          <h1 class="font-weight-regular">{{ feature.title }}</h1>
                          <h4 class="font-weight-regular subtitle-1">
                            {{ feature.text }}
                            </h4>
                        </v-card>
              </template>
              </v-hover>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
export default {
name: "AIfistProdcutSection",
data() {
  return {
    dialog: false,
    videoId: "i8IvvHJssWE",
    features: [
      {
        img: require("@/assets/img/onlinestoreproduct.png"),
        title: "AIFist Online Store",
        text: "Created an Online Store app for easy product management, seamless shopping, and secure transactions. Empowering sellers and buyers with efficient features.",
      },
      {
        img: require("@/assets/img/appui.gif"),
        title: "Cropix",
        text: "A mobile app for farmers, simplifying calculations and providing crop performance insights. Save time, make better decisions, and increase profitability with valuable agricultural insights.",
      },
      {
        img: require("@/assets/img/laborAppui.png"),
        title: "Labor App",
        text: "Designed a Labor app to organize tasks efficiently. Employers connect with workers, create job listings, and streamline communication, enhancing overall productivity.",
      },
    ],
  };
},

};
</script>

<style scoped>

#products {
  height: auto;
}

.content-image {
  scale: 1.2;
  height: 20vh;
  width: 70%;
  margin-top: 2rem;
  margin-bottom: 2rem;
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.title-text {
  margin-top: 2rem;
  font-size: 2.7rem;
  font-weight: bold;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  text-align: center;
}

.title-down-text {
  font-weight: 100;
  font-size: 1.7rem;
  color: #7b7b7b;
}

.card {
  color: #4a4a4a;
  margin-top: 1.6rem;
  min-height: 345px;
  padding: 10px;
  border-radius: 30px 5px 30px 5px;
  transition: 0.5s ease-out;
  background-color: #F4F7F5;
}

.card .v-image {
  margin-bottom: 15px;
  transition: 0.75s;
}

.card h1 {
  font-size: 2.1rem;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.zoom-efect {
  transform: scale(1.1);
  transition: 0.8s;
}

.up {
  transform: translateY(-20px);
  transition: 0.5s ease-out;
}
.subtitle-1 {
  color: #7b7b7b;
  font-size: 1.1rem;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 1rem;
}
</style>